import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import LandingPage from "./pages/landing";
import PrivacyPolicy from "./pages/privacy";
import SettingsPage from "./pages/settings";
import LeagueTablePage from "./pages/admin/leagues";
import EventsPage from "./pages/admin/events";
import { Box } from "@mui/material";
import SchedulesPage from "./pages/admin/schedules";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60 * 15, // Data will not be considered stale for 15 minutes
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Box sx={{ padding: 2 }}>
          <Routes>
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/" element={<LandingPage />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/settings/leagues" element={<LeagueTablePage />} />
            <Route path="/settings/events" element={<EventsPage />} />
            <Route path="/settings/schedules" element={<SchedulesPage />} />
          </Routes>
        </Box>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
