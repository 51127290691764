import React, { useState, useEffect } from "react";
import { Box, TextField } from "@mui/material";
import { EventResultSchema, EventUpdateRequestSchema } from "../schemas/events";
import BaseModal from "./baseModal";

interface EventEditModalProps {
  open: boolean;
  event: EventResultSchema | null;
  onClose: () => void;
  onSave: (updatedEvent: EventResultSchema) => void;
}

const EventEditModal: React.FC<EventEditModalProps> = ({
  open,
  event,
  onClose,
  onSave,
}) => {
  const [editableEvent, setEditableEvent] = useState<EventResultSchema | null>(
    null
  );

  // Initialize local state when the modal opens
  useEffect(() => {
    setEditableEvent(event);
  }, [event]);

  // Handle field changes dynamically
  const handleFieldChange = (field: keyof EventResultSchema, value: string) => {
    if (editableEvent) {
      setEditableEvent({
        ...editableEvent,
        [field]: value,
      });
    }
  };

  // Handle save action
  const handleSave = () => {
    if (editableEvent) {
      onSave(editableEvent);
    }
  };

  // Render input fields based on event properties
  const renderField = (
    field: keyof EventUpdateRequestSchema,
    label: string
  ) => {
    const value = editableEvent ? editableEvent[field] : "";
    const isDateField = field === "event_date";
    const isNullableField = value === null || value === undefined;

    return (
      <TextField
        key={field}
        label={label}
        value={isNullableField ? "" : value}
        onChange={(e) => handleFieldChange(field, e.target.value)}
        type={isDateField ? "datetime-local" : "text"}
        fullWidth
        margin="normal"
        InputLabelProps={isDateField ? { shrink: true } : {}}
        InputProps={{
          ...(isDateField &&
            value && {
              value: typeof value === "string" ? value.slice(0, 16) : "",
            }),
        }}
      />
    );
  };

  if (!editableEvent) return null;

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      title="Edit Event"
      onPrimaryAction={handleSave}
      primaryActionText="Save"
      secondaryActionText="Cancel"
    >
      {/* Render all fields dynamically */}
      <Box sx={{ overflowY: "auto", flex: 1, mb: 4 }}>
        {Object.keys(editableEvent).map((key) => {
          const typedKey = key as keyof EventUpdateRequestSchema;
          if (key === "uuid" || key === "league_id" || key === "event_id") {
            return null;
          }
          return renderField(typedKey, key.replace(/_/g, " ").toUpperCase());
        })}
      </Box>
    </BaseModal>
  );
};

export default EventEditModal;
