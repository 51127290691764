import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  Link,
  Container,
} from "@mui/material";
import { Header } from "../components/header";

const PrivacyPolicy: React.FC = () => {
  return (
    <Container maxWidth="md">
      <Header />
      <Box
        sx={{
          padding: 4,
          maxWidth: 800,
          margin: "auto",
          fontFamily: "Arial, sans-serif",
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          This app respects your privacy and is committed to ensuring that your
          personal data is never collected, stored, or shared.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          No Data Collection
        </Typography>
        <Typography variant="body1" paragraph>
          We do not collect or process any personal data. This means we do not
          store or share information such as:
        </Typography>
        <List>
          <ListItem>• Your name</ListItem>
          <ListItem>• Email address</ListItem>
          <ListItem>• Location</ListItem>
          <ListItem>• Device data</ListItem>
          <ListItem>• Any other identifiable information</ListItem>
        </List>

        <Typography variant="h5" component="h2" gutterBottom>
          Third-Party Services
        </Typography>
        <Typography variant="body1" paragraph>
          This app does not use any third-party services that track or collect
          your data.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          Updates to This Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update this Privacy Policy from time to time. Any changes will
          be reflected here, and we encourage you to review this page
          periodically to stay informed.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          Contact
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about this Privacy Policy, please contact us
          at{" "}
          <Link href="mailto:hello@sportstoday.app">hello@sportstoday.app</Link>
          .
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
