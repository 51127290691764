import React, { useState, useEffect } from "react";
import { Box, TextField, Button, Typography, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SettingsPage: React.FC = () => {
  const [authKey, setAuthKey] = useState("");
  const [storedKey, setStoredKey] = useState<string | null>("");
  const navigate = useNavigate();

  useEffect(() => {
    // Load the current authKey from localStorage when the component mounts
    const key = localStorage.getItem("authKey");
    setStoredKey(key);
  }, []);

  const handleSave = () => {
    localStorage.setItem("authKey", authKey);
    setStoredKey(authKey); // Update the displayed value
    setAuthKey(""); // Clear the input field
  };

  const handleNavigateToLeagues = () => {
    navigate("/settings/leagues");
  };

  const handleNavigateToEvents = () => {
    navigate("/settings/events");
  };
  const handleNavigateToSchedules = () => {
    navigate("/settings/schedules");
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          padding: 4,
          textAlign: "center",
          fontFamily: "Arial, sans-serif",
        }}
      >
        <Typography variant="h4" gutterBottom>
          Set Auth Key
        </Typography>
        <TextField
          label="Auth Key"
          value={authKey}
          onChange={(e) => setAuthKey(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          sx={{ marginTop: 2 }}
        >
          Save to Local Storage
        </Button>
        <Typography variant="h6" sx={{ marginTop: 4 }}>
          Current Stored Auth Key:
        </Typography>
        <Typography variant="body1" sx={{ wordBreak: "break-all" }}>
          {storedKey || "No key set"}
        </Typography>
        <Box sx={{ marginTop: 4 }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleNavigateToLeagues}
          >
            Go to Leagues Settings
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleNavigateToEvents}
          >
            Go to Events Settings
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleNavigateToSchedules}
          >
            Go to Schedule Settings
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default SettingsPage;
