import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import {
  ScheduleResultSchema,
  ScheduleUpdateSchema,
} from "../schemas/schedules";
import BaseModal from "./baseModal";

interface ScheduleEditModalProps {
  open: boolean;
  schedule: ScheduleResultSchema | null;
  onClose: () => void;
  onSave: (updatedSchedule: ScheduleResultSchema) => void;
}

const ScheduleEditModal: React.FC<ScheduleEditModalProps> = ({
  open,
  schedule,
  onClose,
  onSave,
}) => {
  const [editableSchedule, setEditableSchedule] =
    useState<ScheduleResultSchema | null>(null);

  // Initialize local state when the modal opens
  useEffect(() => {
    setEditableSchedule(schedule);
  }, [schedule]);

  // Handle field changes dynamically
  const handleFieldChange = (
    field: keyof ScheduleUpdateSchema,
    value: string | number
  ) => {
    if (editableSchedule) {
      setEditableSchedule({
        ...editableSchedule,
        [field]: value,
      });
    }
  };

  // Handle save action
  const handleSave = () => {
    if (editableSchedule) {
      onSave(editableSchedule);
    }
  };

  if (!editableSchedule) return null;

  return (
    <BaseModal
      open={open}
      title="Edit Schedule"
      onClose={onClose}
      onPrimaryAction={handleSave}
      primaryActionText="Save"
      secondaryActionText="Cancel"
    >
      <TextField
        label="Name"
        value={editableSchedule.name}
        onChange={(e) => handleFieldChange("name", e.target.value)}
        fullWidth
        margin="normal"
      />
      {/* Start Date Field */}
      <TextField
        label="Start Date (UTC)"
        type="datetime-local"
        value={
          typeof editableSchedule.start_date === "string"
            ? editableSchedule.start_date.slice(0, 16)
            : ""
        }
        onChange={(e) => handleFieldChange("start_date", e.target.value)}
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />

      {/* End Date Field */}
      <TextField
        label="End Date (UTC)"
        type="datetime-local"
        value={
          typeof editableSchedule.end_date === "string"
            ? editableSchedule.end_date.slice(0, 16)
            : ""
        }
        onChange={(e) => handleFieldChange("end_date", e.target.value)}
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />

      {/* Frequency Field */}
      <TextField
        label="Frequency (in days)"
        type="number"
        value={editableSchedule.frequency}
        onChange={(e) => handleFieldChange("frequency", Number(e.target.value))}
        fullWidth
        margin="normal"
      />
      {/* Config values */}
      <TextField
        label="Season (SDB)"
        value={editableSchedule.season || ""}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="SportDevs League ID"
        value={editableSchedule.sportdevs_league_id || "NA"}
        fullWidth
        margin="normal"
        disabled
      />
    </BaseModal>
  );
};

export default ScheduleEditModal;
