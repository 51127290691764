import React from "react";
import { Button, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const Header: React.FC = () => {
  const navigation = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        py: 2,
      }}
    >
      {/* Logo and Title */}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <img
          src="/logo512.png"
          alt="Sports Today Logo"
          style={{
            width: 40,
            height: 40,
            marginRight: 8,
            borderRadius: "8px",
          }}
          onClick={() => {
            navigation("/");
          }}
        />
        <Typography variant="h6" component="div">
          Sports Today
        </Typography>
      </Box>

      {/* Mail link */}
      <a href="mailto:hello@sportstoday.app?subject=Feedback%20Sports%20Today">
        <Button
          variant="contained"
          sx={{
            backgroundColor: "rgb(254, 215, 9)", // Custom background color
            color: "rgb(0, 0, 0)", // Custom text color
            borderRadius: "20px",
          }}
        >
          Questions?
        </Button>
      </a>
    </Box>
  );
};
