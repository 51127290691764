import { useMutation, useQuery } from "@tanstack/react-query";

import client from "./client"; // Import your axios client
import { EventResultSchema, EventUpdateRequestSchema } from "../schemas/events";

// Get all events
export const useGetFilteredEvents = (
  leagueId: string | null,
  startDate: string | null,
  endDate: string | null
) => {
  const queryParameters = new URLSearchParams();
  if (leagueId) queryParameters.append("league_id", leagueId);
  if (startDate) queryParameters.append("start_date", startDate);
  if (endDate) queryParameters.append("end_date", endDate);
  return useQuery<EventResultSchema[], Error>({
    queryKey: ["events", leagueId, startDate, endDate], // Unique query key based on parameters
    queryFn: async () => {
      const response = await client.get<EventResultSchema[]>(
        `/events/filter?${queryParameters}`
      );
      return response.data;
    },
    enabled: !!leagueId && !!startDate && !!endDate, // Ensure query only runs if all parameters are provided
  });
};

export const useUpdateEvent = () => {
  return useMutation({
    mutationFn: ({
      eventUuid,
      data,
    }: {
      eventUuid: string;
      data: EventUpdateRequestSchema;
    }) => {
      return client.put(`/events/${eventUuid}`, data);
    },
    onError: (error: unknown) => {
      console.error("Error updating event:", error);
    },
  });
};
