import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { ScheduleCreateSchema } from "../schemas/schedules";
import BaseModal from "./baseModal";
import { useGetAllLeagues } from "../queries/leagues";

interface ScheduleCreateModalProps {
  open: boolean;
  onClose: () => void;
  onCreate: (newSchedule: ScheduleCreateSchema) => void;
}

const ScheduleCreateModal: React.FC<ScheduleCreateModalProps> = ({
  open,
  onClose,
  onCreate,
}) => {
  const [newSchedule, setNewSchedule] = useState<ScheduleCreateSchema>({
    name: "",
    league_id: 0,
    season: "",
    sportdevs_league_id: undefined,
    start_date: "",
    end_date: "",
    frequency: 1,
  });

  const { data: leagues } = useGetAllLeagues();

  const getSource = (leagueId: number) => {
    return leagues?.find((league) => league.league_id === leagueId)?.source_id;
  };

  const handleFieldChange = (
    field: keyof ScheduleCreateSchema,
    value: string | number | undefined
  ) => {
    setNewSchedule({
      ...newSchedule,
      [field]: value,
    });
  };

  const handleCreate = () => {
    if (
      newSchedule.name &&
      newSchedule.league_id &&
      newSchedule.start_date &&
      newSchedule.end_date &&
      newSchedule.frequency > 0
    ) {
      onCreate(newSchedule);
    }
  };

  return (
    <BaseModal
      open={open}
      title="Create New Schedule"
      onClose={onClose}
      onPrimaryAction={handleCreate}
      primaryActionText="Create"
      secondaryActionText="Cancel"
    >
      {/* Form Fields */}
      <TextField
        label="Name"
        value={newSchedule.name}
        onChange={(e) => handleFieldChange("name", e.target.value)}
        fullWidth
        margin="normal"
      />
      <FormControl fullWidth>
        <InputLabel id="league-id">League ID</InputLabel>
        <Select
          labelId="league-id"
          id="league-id"
          value={newSchedule.league_id}
          label="League ID"
          onChange={(e) => handleFieldChange("league_id", e.target.value)}
        >
          {leagues?.map((league) => (
            <MenuItem key={league.league_id} value={league.league_id}>
              {league.league_id} {league.league_name} - {league.sport_name} (
              {league.league_sex})
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        label="Season"
        value={newSchedule.season || ""}
        onChange={(e) => handleFieldChange("season", e.target.value)}
        fullWidth
        margin="normal"
        disabled={getSource(newSchedule.league_id) !== "SDB"}
      />
      <TextField
        label="SportDevs League ID"
        type="number"
        value={newSchedule.sportdevs_league_id || ""}
        onChange={(e) =>
          handleFieldChange(
            "sportdevs_league_id",
            e.target.value ? Number(e.target.value) : undefined
          )
        }
        fullWidth
        margin="normal"
        disabled={getSource(newSchedule.league_id) !== "SPORTDEVS"}
      />
      <TextField
        label="Start Date (UTC)"
        type="datetime-local"
        value={newSchedule.start_date}
        onChange={(e) => handleFieldChange("start_date", e.target.value)}
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        label="End Date (UTC)"
        type="datetime-local"
        value={newSchedule.end_date}
        onChange={(e) => handleFieldChange("end_date", e.target.value)}
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        label="Frequency (in days)"
        type="number"
        value={newSchedule.frequency}
        onChange={(e) => handleFieldChange("frequency", Number(e.target.value))}
        fullWidth
        margin="normal"
      />
    </BaseModal>
  );
};

export default ScheduleCreateModal;
