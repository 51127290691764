import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useGetAllLeagues, useUpdateLeague } from "../../queries/leagues";
import { LeagueDetailsResultSchema } from "../../schemas/league";
import LeagueEditModal from "../../components/editLeagueModal";
import { useNavigate } from "react-router-dom";

const LeaguesPage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLeague, setSelectedLeague] =
    useState<LeagueDetailsResultSchema | null>(null);
  const [leagues, setLeagues] = useState<LeagueDetailsResultSchema[]>([]); // Local leagues state
  const navigate = useNavigate();
  const {
    data: fetchedLeagues,
    isLoading,
    isError,
    error,
  } = useGetAllLeagues();

  const {
    mutate,
    isError: isUpdateError,
    isSuccess: isUpdateSuccess,
  } = useUpdateLeague();

  const handleNavigateLeagueEvents = (leagueId: number) => {
    // Navigate to the events page with the leagueId as a query
    navigate(`/settings/events?leagueId=${leagueId}`);
  };

  const handleEditLeague = (league: LeagueDetailsResultSchema) => {
    setSelectedLeague(league);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedLeague(null);
  };

  const handleSaveLeague = (updatedLeague: LeagueDetailsResultSchema) => {
    mutate({
      leagueId: updatedLeague.league_id,
      data: {
        ...updatedLeague,
      },
    });
    // Update the leagues list locally after saving
    setLeagues((prevLeagues) =>
      prevLeagues.map((league) =>
        league.league_id === updatedLeague.league_id ? updatedLeague : league
      )
    );
    handleCloseModal();
  };

  useEffect(() => {
    if (fetchedLeagues) {
      setLeagues(fetchedLeagues); // Set the leagues when data is fetched
    }
  }, [fetchedLeagues]);

  return (
    <Box mx="auto" mt={4} maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Leagues
      </Typography>

      {isUpdateSuccess && (
        <Typography color="success">League updated successfully!</Typography>
      )}
      {isUpdateError && (
        <Typography color="error">
          An error occurred while updating the league.
        </Typography>
      )}

      {isLoading ? (
        <CircularProgress />
      ) : isError ? (
        <Typography color="error">
          {error instanceof Error ? error.message : "An error occurred."}
        </Typography>
      ) : leagues && leagues.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Edit</TableCell>
                <TableCell>League ID</TableCell>
                <TableCell>Sport Name</TableCell>
                <TableCell>League Name</TableCell>
                <TableCell>Active?</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leagues.map((league) => (
                <TableRow key={league.league_id}>
                  <TableCell>
                    <IconButton onClick={() => handleEditLeague(league)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell
                    onClick={() => handleNavigateLeagueEvents(league.league_id)}
                  >
                    {league.league_id}
                  </TableCell>
                  <TableCell>
                    {league.sport_name}
                    {league.sport_subname ? " - " + league.sport_subname : ""}
                  </TableCell>
                  <TableCell>
                    {league.league_name} ({league.league_sex})
                  </TableCell>
                  <TableCell>{league.is_active ? "Yes" : "No"}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>No leagues found for the given criteria.</Typography>
      )}

      <LeagueEditModal
        open={isModalOpen}
        league={selectedLeague}
        onClose={handleCloseModal}
        onSave={handleSaveLeague}
      />
    </Box>
  );
};

export default LeaguesPage;
