import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useGetFilteredEvents, useUpdateEvent } from "../../queries/events";
import { EventResultSchema } from "../../schemas/events";
import EventEditModal from "../../components/editEventModal";
import { useGetAllLeagues } from "../../queries/leagues";
import { LeagueDetailsResultSchema } from "../../schemas/league";
import { useSearchParams } from "react-router-dom";

const EventsPage: React.FC = () => {
  const [searchParams] = useSearchParams();

  const [leagueId, setLeagueId] = useState<string>(
    searchParams.get("leagueId") || ""
  );
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string | null>(null);

  const [submittedLeagueId, setSubmittedLeagueId] = useState<string | null>(
    null
  );
  const [submittedStartDate, setSubmittedStartDate] = useState<string | null>(
    null
  );
  const [submittedEndDate, setSubmittedEndDate] = useState<string | null>(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<EventResultSchema | null>(
    null
  );
  const [events, setEvents] = useState<EventResultSchema[]>([]); // Local events state
  const [leagues, setLeagues] = useState<LeagueDetailsResultSchema[]>([]);

  const { data: fetchedLeagues } = useGetAllLeagues();

  const {
    data: fetchedEvents,
    isLoading,
    isError,
    error,
    refetch,
  } = useGetFilteredEvents(
    submittedLeagueId,
    submittedStartDate,
    submittedEndDate
  );

  const {
    mutate,
    isError: isUpdateError,
    isSuccess: isUpdateSuccess,
  } = useUpdateEvent();

  const handleSearch = () => {
    setSubmittedLeagueId(leagueId);
    setSubmittedStartDate(startDate);
    setSubmittedEndDate(endDate);
  };

  const handleEditEvent = (event: EventResultSchema) => {
    setSelectedEvent(event);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedEvent(null);
  };

  const handleSaveEvent = (updatedEvent: EventResultSchema) => {
    mutate({
      eventUuid: updatedEvent.uuid,
      data: {
        ...updatedEvent,
      },
    });
    // Update the events list locally after saving
    setEvents((prevEvents) =>
      prevEvents.map((event) =>
        event.uuid === updatedEvent.uuid ? updatedEvent : event
      )
    );
    handleCloseModal();
  };

  useEffect(() => {
    if (fetchedEvents) {
      setEvents(fetchedEvents);
    }
  }, [fetchedEvents]);

  useEffect(() => {
    if (fetchedLeagues) {
      setLeagues(fetchedLeagues);
    }
  }, [fetchedLeagues]);

  useEffect(() => {
    if (submittedLeagueId || submittedStartDate || submittedEndDate) {
      refetch();
    }
  }, [submittedLeagueId, submittedStartDate, submittedEndDate, refetch]);

  return (
    <Box mx="auto" mt={4} maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Events
      </Typography>
      <Box display="block" mb={3}>
        <FormControl fullWidth>
          <InputLabel id="league-id">League ID</InputLabel>
          <Select
            labelId="league-id"
            id="league-id"
            value={leagueId}
            label="League ID"
            onChange={(e) => setLeagueId(e.target.value)}
          >
            {leagues.map((league) => (
              <MenuItem key={league.league_id} value={league.league_id}>
                {league.league_id} {league.league_name} - {league.sport_name} (
                {league.league_sex})
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Start Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="End Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={endDate || ""}
          onChange={(e) => setEndDate(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          disabled={!leagueId && !startDate && !endDate}
          fullWidth
        >
          Search
        </Button>
      </Box>

      {isUpdateSuccess && (
        <Typography color="success">Event updated successfully!</Typography>
      )}
      {isUpdateError && (
        <Typography color="error">
          An error occurred while updating the event.
        </Typography>
      )}

      {isLoading ? (
        <CircularProgress />
      ) : isError ? (
        <Typography color="error">
          {error instanceof Error ? error.message : "An error occurred."}
        </Typography>
      ) : events && events.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Edit</TableCell>
                <TableCell>Event ID</TableCell>
                <TableCell>Event Name</TableCell>
                <TableCell>Date (UTC)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {events.map((event) => (
                <TableRow key={event.event_id}>
                  <TableCell>
                    <IconButton onClick={() => handleEditEvent(event)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>{event.event_id}</TableCell>
                  <TableCell>{event.event_name}</TableCell>
                  <TableCell>{event.event_date.slice(0, 16)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>No events found for the given criteria.</Typography>
      )}

      <EventEditModal
        open={isModalOpen}
        event={selectedEvent}
        onClose={handleCloseModal}
        onSave={handleSaveEvent}
      />
    </Box>
  );
};

export default EventsPage;
