import { useMutation, useQuery } from "@tanstack/react-query";

import client from "./client"; // Import your axios client
import {
  LeagueDetailsResultSchema,
  LeagueUpdateRequestSchema,
} from "../schemas/league";

// Get all leagues
export const useGetAllLeagues = () => {
  return useQuery({
    queryKey: ["leagues"],
    queryFn: async () => {
      const response = await client.get<LeagueDetailsResultSchema[]>(
        "/leagues/details"
      );
      return response.data;
    },
  });
};

export const useUpdateLeague = () => {
  return useMutation({
    mutationFn: ({
      leagueId,
      data,
    }: {
      leagueId: number;
      data: LeagueUpdateRequestSchema;
    }) => {
      return client.put(`/leagues/${leagueId}`, data);
    },
    onError: (error: unknown) => {
      console.error("Error updating league:", error);
    },
  });
};
