import React, { useState, useEffect } from "react";
import { Box, TextField, FormControlLabel, Checkbox } from "@mui/material";
import {
  LeagueDetailsResultSchema,
  LeagueResultSchema,
  LeagueUpdateRequestSchema,
} from "../schemas/league";
import BaseModal from "./baseModal";

interface LeagueEditModalProps {
  open: boolean;
  league: LeagueDetailsResultSchema | null;
  onClose: () => void;
  onSave: (updatedLeague: LeagueDetailsResultSchema) => void;
}

const LeagueEditModal: React.FC<LeagueEditModalProps> = ({
  open,
  league,
  onClose,
  onSave,
}) => {
  const [editableLeague, setEditableLeague] =
    useState<LeagueDetailsResultSchema | null>(null);

  // Initialize local state when the modal opens
  useEffect(() => {
    setEditableLeague(league);
  }, [league]);

  // Handle field changes dynamically
  const handleFieldChange = (
    field: keyof LeagueUpdateRequestSchema,
    value: string | boolean
  ) => {
    if (editableLeague) {
      setEditableLeague({
        ...editableLeague,
        [field]: value,
      });
    }
  };

  // Handle save action
  const handleSave = () => {
    if (editableLeague) {
      onSave(editableLeague);
    }
  };

  // Render input fields based on league properties
  const renderField = (
    field: keyof LeagueUpdateRequestSchema,
    label: string
  ) => {
    const value =
      editableLeague && field in editableLeague
        ? editableLeague[field as keyof LeagueResultSchema]
        : "";
    const isNullableField = value === null || value === undefined;

    if (field === "is_active") {
      return (
        <FormControlLabel
          key={field}
          label={label}
          control={
            <Checkbox
              checked={Boolean(value)}
              onChange={(e) => handleFieldChange(field, e.target.checked)}
            />
          }
        />
      );
    }

    return (
      <TextField
        key={field}
        label={label}
        value={isNullableField ? "" : value}
        onChange={(e) => handleFieldChange(field, e.target.value)}
        fullWidth
        margin="normal"
      />
    );
  };

  if (!editableLeague) return null;

  return (
    <BaseModal
      open={open}
      title="Edit League"
      onClose={onClose}
      onPrimaryAction={handleSave}
      primaryActionText="Save"
      secondaryActionText="Cancel"
    >
      {/* Render all fields dynamically */}
      <Box sx={{ overflowY: "auto", flex: 1, mb: 4 }}>
        {Object.keys(editableLeague).map((key) => {
          const typedKey = key as keyof LeagueUpdateRequestSchema;
          if (key === "uuid" || key === "league_id") {
            return null;
          }
          return renderField(typedKey, key.replace(/_/g, " ").toUpperCase());
        })}
      </Box>
    </BaseModal>
  );
};

export default LeagueEditModal;
