import { useMutation, useQuery } from "@tanstack/react-query";

import client from "./client"; // Import your axios client
import {
  ScheduleCreateSchema,
  ScheduleResultSchema,
  ScheduleUpdateSchema,
  TriggerScheduleSchema,
} from "../schemas/schedules";

// Get all schedules
export const useGetAllSchedules = () => {
  return useQuery({
    queryKey: ["schedule"],
    queryFn: async () => {
      const response = await client.get<ScheduleResultSchema[]>(
        "/event_updates/schedule"
      );
      return response.data;
    },
  });
};

// Update a schedule
export const useUpdateSchedule = () => {
  return useMutation({
    mutationFn: ({
      scheduleId: scheduleUuid,
      data,
    }: {
      scheduleId: string;
      data: ScheduleUpdateSchema;
    }) => {
      return client.put(`/event_updates/schedule/${scheduleUuid}`, data);
    },
    onError: (error: unknown) => {
      console.error("Error updating schedule:", error);
    },
  });
};

// Create a schedule
export const useCreateSchedule = () => {
  return useMutation({
    mutationFn: (data: ScheduleCreateSchema) => {
      return client.post("/event_updates/schedule", data);
    },
    onError: (error: unknown) => {
      console.error("Error creating schedule:", error);
    },
  });
};

// Delete a schedule
export const useDeleteSchedule = () => {
  return useMutation({
    mutationFn: (scheduleUuid: string) => {
      return client.delete(`/event_updates/schedule/${scheduleUuid}`);
    },
    onError: (error: unknown) => {
      console.error("Error deleting schedule:", error);
    },
  });
};

// Trigger a schedule
export const useTriggerSchedule = () => {
  return useMutation({
    mutationFn: async (data: TriggerScheduleSchema) => {
      const response = await client.post("/event_updates/trigger", data);
      return response.data;
    },
    onSuccess: (data) => {
      return data;
    },
    onError: (error: unknown) => {
      console.error("Error triggering schedule:", error);
    },
  });
};
