import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import PlayIcon from "@mui/icons-material/PlayArrow";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useCreateSchedule,
  useDeleteSchedule,
  useGetAllSchedules,
  useTriggerSchedule,
  useUpdateSchedule,
} from "../../queries/schedule";
import {
  ScheduleCreateSchema,
  ScheduleResultSchema,
} from "../../schemas/schedules";
import ScheduleEditModal from "../../components/editScheduleModal";
import ScheduleCreateModal from "../../components/createScheduleModal";
import { useGetAllLeagues } from "../../queries/leagues";
import { LeagueDetailsResultSchema } from "../../schemas/league";
import { useNavigate } from "react-router-dom";
import { Delete } from "@mui/icons-material";

const SchedulesPage: React.FC = () => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedSchedule, setSelectedSchedule] =
    useState<ScheduleResultSchema | null>(null);
  const [schedules, setSchedules] = useState<ScheduleResultSchema[]>([]); // Local schedules state
  const [leagues, setLeagues] = useState<{
    [key: number]: LeagueDetailsResultSchema;
  }>({});
  const navigate = useNavigate();

  const handleNavigateLeagueEvents = (leagueId: number) => {
    // Navigate to the events page with the leagueId as a query
    navigate(`/settings/events?leagueId=${leagueId}`);
  };

  // Fetch leagues
  const { data: fetchedLeagues } = useGetAllLeagues();

  // Fetch schedules
  const {
    data: fetchedSchedules,
    isLoading,
    isError,
    error,
    refetch,
  } = useGetAllSchedules();

  // Update schedule
  const {
    mutate: mutateUpdate,
    isError: isUpdateError,
    isSuccess: isUpdateSuccess,
  } = useUpdateSchedule();

  // Delete schedule
  const { mutate: mutateDelete } = useDeleteSchedule();

  // Trigger schedule
  const {
    data: triggerData,
    mutate: mutateTriggerSchedule,
    isSuccess: isTriggerSuccess,
  } = useTriggerSchedule();

  // Create schedule
  const { mutate: mutateCreate } = useCreateSchedule();

  const handleEditSchedule = (schedule: ScheduleResultSchema) => {
    setSelectedSchedule(schedule);
    setIsEditModalOpen(true);
  };

  const handleCreateSchedule = (schedule: ScheduleCreateSchema) => {
    mutateCreate(schedule);
    setIsCreateModalOpen(false);
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
    setSelectedSchedule(null);
  };

  const handleSaveSchedule = (updatedSchedule: ScheduleResultSchema) => {
    mutateUpdate({
      scheduleId: updatedSchedule.uuid,
      data: {
        name: updatedSchedule.name,
        start_date: updatedSchedule.start_date,
        end_date: updatedSchedule.end_date,
        frequency: updatedSchedule.frequency,
      },
    });
    // Update the schedules list locally after saving
    setSchedules((prevSchedules) =>
      prevSchedules.map((schedule) =>
        schedule.uuid === updatedSchedule.uuid ? updatedSchedule : schedule
      )
    );
    handleCloseModal();
  };

  const handleDeleteSchedule = (scheduleUuid: string) => {
    if (window.confirm("Are you sure you want to delete this schedule?")) {
      mutateDelete(scheduleUuid);
      // Update the schedules list locally after deleting
      setSchedules((prevSchedules) =>
        prevSchedules.filter((schedule) => schedule.uuid !== scheduleUuid)
      );
    }
  };

  const mutateTrigger = (scheduleUuid: string) => {
    mutateTriggerSchedule({ league_id: null, schedule_uuid: scheduleUuid });
  };

  useEffect(() => {
    if (fetchedSchedules) {
      setSchedules(fetchedSchedules); // Set the schedules when data is fetched
    }
  }, [fetchedSchedules]);

  useEffect(() => {
    if (fetchedLeagues) {
      // make dict of leagues with league_id as key
      const fetchedLeaguesDict = fetchedLeagues.reduce(
        (acc: { [key: number]: any }, league) => {
          acc[league.league_id] = league;
          return acc;
        },
        {}
      );
      setLeagues(fetchedLeaguesDict);
    }
  }, [fetchedLeagues]);

  useEffect(() => {
    if (isTriggerSuccess) {
      alert(JSON.stringify(triggerData, null, 2));
      refetch();
    }
  }, [isTriggerSuccess, triggerData, refetch]);

  return (
    <Box mx="auto" mt={4} maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Schedules
      </Typography>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <IconButton onClick={() => setIsCreateModalOpen(true)}>
          Create
        </IconButton>
      </Box>

      {isUpdateSuccess && (
        <Typography color="success">Schedule updated successfully!</Typography>
      )}
      {isUpdateError && (
        <Typography color="error">
          An error occurred while updating the schedule.
        </Typography>
      )}

      {isLoading ? (
        <CircularProgress />
      ) : isError ? (
        <Typography color="error">
          {error instanceof Error ? error.message : "An error occurred."}
        </Typography>
      ) : schedules && schedules.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Edit</TableCell>
                <TableCell>League ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>Last update</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {schedules.map((schedule) => (
                <TableRow key={schedule.uuid}>
                  <TableCell>
                    <IconButton onClick={() => handleEditSchedule(schedule)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell
                    onClick={() =>
                      handleNavigateLeagueEvents(schedule.league_id)
                    }
                  >
                    {schedule.league_id} -{" "}
                    {leagues[schedule.league_id]?.league_name}
                  </TableCell>
                  <TableCell>{schedule.name}</TableCell>
                  <TableCell>{schedule.start_date.slice(0, 16)}</TableCell>
                  <TableCell>{schedule.end_date.slice(0, 16)}</TableCell>
                  <TableCell>
                    {schedule.last_update_date
                      ? schedule.last_update_date.slice(0, 16)
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => mutateTrigger(schedule.uuid)}>
                      <PlayIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDeleteSchedule(schedule.uuid)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>No schedules found.</Typography>
      )}

      <ScheduleEditModal
        open={isEditModalOpen}
        schedule={selectedSchedule}
        onClose={handleCloseModal}
        onSave={handleSaveSchedule}
      />
      <ScheduleCreateModal
        open={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        onCreate={handleCreateSchedule}
      />
    </Box>
  );
};

export default SchedulesPage;
