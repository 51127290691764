import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";

interface BaseModalProps {
  open: boolean;
  title: string;
  onClose: () => void;
  onPrimaryAction: () => void;
  primaryActionText?: string;
  secondaryActionText?: string;
  children: React.ReactNode;
}

const BaseModal: React.FC<BaseModalProps> = ({
  open,
  title,
  onClose,
  onPrimaryAction,
  primaryActionText = "Save",
  secondaryActionText = "Cancel",
  children,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          maxHeight: "80vh",
          minWidth: "50vw",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Modal Title */}
        <Typography variant="h6" mb={2}>
          {title}
        </Typography>

        {/* Content */}
        <Box flex="1" overflow="auto">
          {children}
        </Box>

        {/* Action Buttons */}
        <Box
          sx={{
            position: "absolute",
            bottom: 16,
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            px: 2,
          }}
        >
          <Button onClick={onClose} color="secondary">
            {secondaryActionText}
          </Button>
          <Button onClick={onPrimaryAction} variant="contained" color="primary">
            {primaryActionText}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default BaseModal;
